@tailwind base;
@tailwind components;
@tailwind utilities;

/* globals.css */
@keyframes fadeIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.95);
    opacity: 0;
  }
}

.modal-enter {
  animation: fadeIn 0.3s ease-out forwards;
}

.modal-exit {
  animation: fadeOut 0.3s ease-out forwards;
}